<script setup>
  import { storeToRefs } from 'pinia';
  import { useAppStore } from '@/store/appStore';
  import { ref } from 'vue';
  import PluginsMenu from './PluginsMenu.vue';
  import Tooltip from '@/components/common/tooltip.vue';
  import TooltipContent from '@/components/common/TooltipContent.vue';
  import { useI18n } from 'vue-i18n';
  import OnboardingTooltip from "@/components/common/OnboardingTooltip.vue";

  const { currentLanguage } = storeToRefs(useAppStore());

  const { t, locale } = useI18n();
  const props = defineProps(['selectedPlugin']);
  const menuVisible = ref(false);

  const toggleMenu = () => {
    menuVisible.value = !menuVisible.value;
  };

  const closeMenu = () => {
    menuVisible.value = false;
  };
</script>

<template>
  <div
    class="flex flex-1 items-end flex-col gap-2 relative form-control md:items-center md:grow-[3] pluginSelector"
    v-click-outside="closeMenu"
  >
    <label class="label label-text sr-only" for="plugin-selector">Select Plugin</label>
    <OnboardingTooltip tooltip_id="pluginSelection"
                       :heading="t('pluginSelectionHintHeading')"
                       :content="t('pluginSelectionHintContent')">
      <button
        @click="toggleMenu"
        name="plugin-selector"
        id="plugin-selector"
        class="select select-bordered flex items-center"
        type="button"
        aria-haspopup="true"
      >
        <span
          class="text-text-secondary text-base font-normal font-['VodafoneRg'] cursor-pointer textOverflow"
        >
          {{ props.selectedPlugin.displayNames[currentLanguage.name] }}
        </span>
      </button>
    </OnboardingTooltip>

    <PluginsMenu :menuVisible="menuVisible" @closeMenu="closeMenu" />
  </div>
</template>

<style>
  .pluginSelector {
    container-type: inline-size;
  }

  @container (max-width: 200px) {
    .textOverflow {
      max-width: 75px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
</style>
