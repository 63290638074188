<script setup>
  import CardsView from '@/components/home/CardsView.vue';
  import SvgExample from '@/components/common/svg/svgExample.vue';
  import SvgSkill from '@/components/common/svg/svgSkill.vue';
  import SvgSecurity from '@/components/common/svg/svgSecurity.vue';
  import SvgKI from '@/components/common/svg/svgKI.vue';
  import { useI18n } from 'vue-i18n';
  import { useAppStore } from '@/store/appStore.js';

  const appStore = useAppStore();

  const cardIcons = [SvgExample, SvgSkill, SvgSecurity];

  const { t } = useI18n();
</script>

<template>
  <div class="mt-8 flex justify-between flex-col items-center h-full">
    <div class="font-VFRegular">
      <div class="text-[#333] text-center md:text-2xl text-5xl font-bold leading-[4.5rem]">
        <p>{{ t(`welcome.questionTitle`) }}</p>
        <p>{{ t(`welcome.answerTitle`) }}</p>
      </div>

      <div class="flex justify-center items-center gap-icon-text-gap">
        <p class="text-[#333] md:text-base text-[2rem] font-normal leading-[3rem]">
          {{ t(`welcome.description`) }}
        </p>
        <div class="w-6 h-6 md:w-3 md:h-3">
          <svgKI />
        </div>
      </div>
    </div>

    <div class="flex flex-wrap justify-center gap-4 mb-10">
      <div
        v-for="(card, index) in appStore.currentPlugin.displayNames.english === 'GPT-3.5' ||
        appStore.currentPlugin.displayNames.german === 'GPT-3.5'
          ? $tm('welcome.plugins.gpt_3_5_plugin')
          : $tm('welcome.plugins.other')"
        :key="index"
        :header="card"
      >
        <CardsView :svgComponent="cardIcons[index]" :title="card.title" :text="card.description" />
      </div>
    </div>
  </div>
</template>
