<script setup>
  import { usePluginStore } from '@/store/pluginStore.js';
  import { storeToRefs } from 'pinia';
  import { useAppStore } from '@/store/appStore';
  import { useChatListStore } from '@/store/chatListStore.js';
  import ListItem from './ListItem.vue';
  import svgPluginHeader from '@/components/common/svg/svgPluginHeader.vue';
  import svgTick from '@/components/common/svg/svgTick.vue';
  const iconColor = '#0A5C5C';

  const props = defineProps(['menuVisible']);
  const emit = defineEmits(['closeMenu']);

  const { currentPlugin } = storeToRefs(useAppStore());
  const { filteredPlugins } = storeToRefs(usePluginStore());
  const { currentLanguage } = storeToRefs(useAppStore());

  const chatListStore = useChatListStore();

  const handleCreateNewChat = () => {
    chatListStore.createNewChat();
  };

  const handleOnPluginChange = (selectedPluginName) => {
    const plugin = filteredPlugins.value.find((plugin) => plugin.name === selectedPluginName);
    currentPlugin.value = plugin;
  };

  const handleOnChange = (event) => {
    const selectedPluginName = event.target.value;
    handleOnPluginChange(selectedPluginName);
    handleCreateNewChat();
    emit('closeMenu');
  };

  const isSelected = (currentPluginName, selectedPluginName) =>
    currentPluginName === selectedPluginName;

  function debounce(func, delay) {
    let timeoutId = null;

    return function debounce(...args) {
      const later = () => {
        timeoutId = null;
        func.apply(this, args);
      };

      clearTimeout(timeoutId);
      timeoutId = setTimeout(later, delay);
    };
  }

  const debouncedExecuteFunction = debounce(handleOnChange, 1000);
</script>

<template>
  <Transition>
    <form
      v-show="props.menuVisible"
      role="menu"
      aria-labelledby="menuButton"
      aria-orientation="vertical"
      class="absolute top-full right-[11%] p-3 box- z-10 bg-white rounded-md shadow-lg focus:outline-none"
    >
      <fieldset>
        <legend class="w-full block text-left font-bold text-xs mb-2">Plugins</legend>
        <label
          v-for="(plugin, index) in filteredPlugins"
          :for="plugin.id"
          :key="plugin.id"
          :id="`plugin-${index}`"
          role="menuitem"
        >
          <input
            :id="plugin.id"
            type="radio"
            name="plugin"
            class="sr-only"
            :value="plugin.name"
            @change.stop.prevent="debouncedExecuteFunction($event)"
          />

          <ListItem
            :head="svgPluginHeader"
            :headerIconColor="iconColor"
            :tickIconColor="iconColor"
            :tail="svgTick"
            :title="plugin.displayNames[currentLanguage.name]"
            :subTitle="plugin.descriptions[currentLanguage.name]"
            :isSelected="isSelected(plugin.name, currentPlugin.name)"
          ></ListItem>
        </label>
      </fieldset>
    </form>
  </Transition>
</template>

<style scoped>
  label:not(:last-of-type) > div {
    margin-bottom: 8px;
  }

  label:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
